<template>
  <div>
    <div class="filters">
      <el-input v-model="goodsId" @change="search" placeholder="商品ID" style="width: 10rem;"></el-input>
      <el-select v-model="status" @change="search" style="margin-left: 10px;">
        <el-option label="全部" value=""></el-option>
        <el-option label="待审核" :value="false"></el-option>
        <el-option label="已审核" :value="true"></el-option>
      </el-select>
    </div>
    <div class="container">
      <el-table :data="list">
        <el-table-column border label="用户信息">
          <template slot-scope="scope">
            <div class="basic">
              <img :src="scope.row.imgUrl" />
              <div>
                <div>{{ scope.row.nickName }}</div>
                <div>ID：{{ scope.row.member ? scope.row.member.id : "无" }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评价时间">
          <template slot-scope="scope">
            <span>{{ dateFormat(scope.row.createdDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品名称">
          <template slot-scope="scope">
            <p>{{ scope.row.goods.name }}({{ scope.row.goods.id }})</p>
          </template>
        </el-table-column>
        <el-table-column label="商品主图">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.goods.mainImgUrl"
              class="commentImage"
              :preview-src-list="[scope.row.goods.mainImgUrl]"
              :z-index="9999"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="评价文字" prop="comment"> </el-table-column>
        <el-table-column label="评价图片">
          <template slot-scope="scope" v-if="scope.row.imgList">
            <el-image
              v-for="o in scope.row.imgList.split(',')"
              fit="contain "
              :key="o"
              :src="o"
              :preview-src-list="scope.row.imgList.split(',')"
              :z-index="9999"
              class="commentImage"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.status" type="text" @click="approvalComment(scope.row.id, 1)">待发布</el-button>
            <el-button v-else type="text" @click="approvalComment(scope.row.id, 0)">已发布</el-button>
            <el-button type="text" @click="deleteComment(scope.row.id)" style="color: red;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/api/axios'
import { dateFormat } from '@/utils/tools'

export default {
  data () {
    return {
      total: 10,
      pageSize: 10,
      currentPage: 1,
      goodsId: '',
      status: '',
      list: []
    }
  },
  created () {
    this.fetchComment()
  },
  methods: {
    dateFormat,
    async fetchComment () {
      const params = {
        goodsId: this.goodsId,
        page: this.currentPage - 1,
        size: this.pageSize,
        status: this.status,
        sort: 'createdDate,desc'
      }
      const res = await axios.get('/management/goods/comment', { params })
      this.list = res.list
      this.total = res.totalElements
    },
    search () {
      this.currentPage = 1
      this.fetchComment()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchComment()
    },
    handleSizeChange (val) {
      this.currentPage = 1
      this.pageSize = val
      this.fetchComment()
    },
    async approvalComment (id, status) {
      try {
        await axios.put(`/management/goods/comment/${id}/audit`, { status })
        this.$notify.success('操作成功！')
        this.fetchComment()
      } catch (error) {
        this.$notify.success('操作失败！')
      }
    },
    async deleteComment (id) {
      try {
        await axios.delete(`/management/goods/comment/${id}`)
        this.$notify.success('删除成功！')
        this.fetchComment()
      } catch (error) {
        this.$notify.success('删除失败！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 20px;
  background-color: #fff;
  text-align: left;
}
.basic {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
.commentImage {
  padding: 6px;
  /deep/ img {
    max-width: 80px;
    max-height: 80px;
  }
}
.pagination {
  background-color: #fff;
  padding: 10px 0;
}
</style>
